<template>
  <div class="form-radio">
    <div
      v-for="(option, index) in options"
      :key="index"
      class="radio-group"
    >
      <FormRadioOption
        :option="option"
        :index="index"
        :get="get"
        :set="set"
      />
    </div>
  </div>
</template>

<script>
import FormRadioOption from './FormRadioOption.vue';

export default {
  name: 'FormRadio',
  components: {
    FormRadioOption,
  },
  props: {
    options: {
      type: Array,
      required: true,
    },
    get: {
      type: String,
      required: true,
    },
    set: {
      type: String,
      required: true,
    },
  },
};
</script>
