<template>
  <label class="form-radio-option d-flex">
    <div class="form-radio-option-button-container">
      <input
        v-model="selected"
        :value="option.value"
        :aria-describedby="get"
        :aria-label="`${option.name || ''}, ${option.type || ''}, ${price || ''}, ${option.payment || ''}`"
        class="form-radio-option-button"
        type="radio"
      >
    </div>
    <div
      class="buttons-select-option-button"
      aria-hidden="true"
    >
      <div
        v-if="option.type"
        aria-hidden="true"
        class="buttons-select-option-content-left float-left"
      >
        <span
          aria-hidden="true"
          class="buttons-select-option-content-left-main float-left"
        >{{ option.name }}</span>
        <span
          aria-hidden="true"
          class="buttons-select-option-content-left-sub float-left"
        >{{ option.type }}</span>
      </div>
      <div
        v-else
        aria-hidden="true"
        class="buttons-select-option-content-center"
      >
        <span
          aria-hidden="true"
          class="buttons-select-option-content-left-main float-left"
        >{{ option.name }}</span>
      </div>
      <div
        v-if="option.payment"
        aria-hidden="true"
        class="buttons-select-option-content-right float-right"
      >
        <span
          aria-hidden="true"
          class="buttons-select-option-content-right-main"
        >{{ option.displayPrice }}</span>
        <span
          aria-hidden="true"
          class="buttons-select-option-content-right-sub"
        >{{ option.payment }}</span>
      </div>
      <div
        v-else
        aria-hidden="true"
        class="buttons-select-option-content-center float-right"
      >
        <span
          aria-hidden="true"
          class="buttons-select-option-content-right-main"
        >{{ option.displayPrice }}</span>
      </div>
    </div>
  </label>
</template>

<script>

export default {
  name: 'FormRadioOption',
  props: {
    option: {
      type: Object,
      required: true,
    },
    index: {
      type: Number,
      required: true,
    },
    get: {
      type: String,
      required: true,
    },
    set: {
      type: String,
      required: true,
    },
  },
  computed: {
    selected: {
      get() {
        return this.$store.getters[this.get];
      },
      set(val) {
        this.$store.commit(this.set, val);
      },
    },
    price() {
      if (!this.option.currency) return '';
      return new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: this.option.currency,
        currencyDisplay: 'symbol',
        minimumFractionDigits: 0,
      }).format(this.option.price / 100);
    },
  },
};
</script>

<style lang='scss' scoped>
@import '../../styles/variables/_colors.scss';
@import '../../styles/objects/_form.scss';

.form-radio-option {
  position: relative;
  label {
    margin-bottom: 0px;
  }
  height: 60px !important;
  width: 100% !important;
  border: 1px solid $aira-color-border;
  border-radius: 2px;
  margin-bottom: 20px;
  .form-radio-option-button-container {
    width: 60px !important;
    margin: 5px !important;
    border-right: 1px solid $aira-color-border;
    display: inline-block;
    vertical-align: top;
    .form-radio-option-button {
      height: inherit !important;
      margin: 18px;
      // position: absolute;
      // z-index: 5;
      // top: 0;
      // left: 18px;
      // opacity: 0;
      &:not(old):checked {
        opacity: 1;
        border: 1px solid #21bece !important;
        border-radius: 2px;
      }
    }
  }
  .buttons-select-option-button {
    height: inherit;
    color: #2a2a2a !important;
  }
  .buttons-select-option-content-center {
    padding: 17px 15px 0 15px;
    display: inline-block;
  }
  .buttons-select-option-content-left {
    padding: 11px 0 0 15px;
  }
  .buttons-select-option-content-right {
    padding: 11px 20px 0 0;
  }
  .buttons-select-option-content-left-main, .buttons-select-option-content-right-main {
    line-height: 1.5;
  }
}
</style>
