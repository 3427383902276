<template>
  <div
    v-if="hasPageAccess(pageName)"
    id="reseller-purchase-order"
  >
    <h2 aria-label="Purchase Order Form">
      Purchase Order
    </h2>
    <b-form
      class="form-container"
      @submit.prevent
    >
      <FormProgressBar :progress="progress()" />
      <b-btn
        aria-label="back"
        variant="link"
        class="back-button"
        @click="clickBack"
      >
        <i class="material-icons">arrow_back</i>
      </b-btn>
      <!-- ERRORS -->
      <FormErrors />
      <div class="form">
        <!-- PAYMENT PROFILE -->
        <div v-if="initializing">
          <b-spinner
            variant="primary"
            label="loading"
            class="spinner"
            aria-hidden
          />
        </div>
        <FormSection
          v-else
          title="Billing Information"
          class="w-100 billing"
        >
          <SignupSummaryInfo
            variant="payment-profile"
            route="/reseller/profile/edit/billing"
            link-text="Update Payment Profile"
          />
        </FormSection>
        <div v-if="cardType || achAccountType">
          <!-- PHONE VERIFICATION -->
          <FormSection
            title="Phone Verification"
            description="Check to see if the Explorer doesn't already have a subscription."
          >
            <div class="d-inline-flex justify-content-between form-inline-input">
              <FormGroup
                for="countryCode"
                label="Country Code"
                class="w-25-35"
              >
                <FormSelect
                  :options="countryCodeOptions"
                  bind-id="countryCode"
                  default="+1"
                  get="countryCode"
                  set="UPDATE_COUNTRY_CODE"
                />
              </FormGroup>
              <FormGroup
                :state="phoneVerifyStatus"
                for="phoneNumber"
                label="Phone Number"
                invalid-feedback="Please re-verify the phone number."
                valid-feedback="Phone number is verified!"
                class="w-75-65"
              >
                <FormInput
                  :state="phoneVerifyStatus"
                  :disabled="phoneVerifyButtonDisabled"
                  bind-id="phoneNumber"
                  type="tel"
                  placeholder="Mobile Number"
                  get="phoneNumber"
                  set="UPDATE_PHONE_NUMBER"
                  @keyup.enter.native="verifyPhone"
                />
              </FormGroup>
            </div>
            <b-button
              id="phone-verify-button"
              :disabled="phoneVerifyButtonDisabled || phoneVerified"
              variant="primary"
              class="form-inline-button"
              @click="verifyPhone"
            >
              <span v-if="phoneVerified">
                Verified
              </span>
              <span v-else-if="phoneVerifyButtonDisabled">
                <b-spinner label="verifying" />
              </span>
              <span v-else>Check</span>
            </b-button>
          </FormSection>
          <div v-if="phoneVerified">
            <!-- CHOOSE SUBSCRIPTION -->
            <FormSection title="Choose Subscription">
              <FormSelect
                :options="planOptions"
                bind-id="planCode"
                aria-label="subscription"
                get="planCode"
                set="SET_PLAN_CODE"
                required
              />
              <ul
                v-if="planCode"
                id="plan-info"
              >
                <!-- eslint-disable -->
                <li
                  v-for="(info, index) in plan.info"
                  v-if="info"
                  :key="index">
                  {{ info }}
                </li>
                <!-- eslint-enable -->
              </ul>
            </FormSection>
            <!-- HORIZON KIT -->
            <FormSection
              v-if="planCode"
              title="Horizon Kit"
              description="Add an optional horizon kit. You will have the option to choose the shipping address below."
            >
              <FormRadio
                :options="hardwareOptions"
                get="hardwareSelected"
                set="SET_HARDWARE_SELECTED"
              />
            </FormSection>
            <FormSection
              v-if="hardwareSelected !== 'none'"
              title="Horizon Kit Preferences"
            >
              <div
                v-for="preference in hardwarePreferences"
                :key="preference.get"
              >
                <FormSection
                  :description="preference.description"
                >
                  <FormGroup
                    :for="preference.get"
                    class="w-100"
                  >
                    <FormSelect
                      :options="preference.options"
                      :bind-id="preference.get"
                      :get="preference.get"
                      :set="preference.set"
                      required
                    />
                  </FormGroup>
                </FormSection>
              </div>
            </FormSection>
            <!-- CUSTOMER INFORMATION -->
            <FormSection title="Customer Information">
              <!-- FIRST & LAST NAME -->
              <div class="d-sm-flex justify-content-sm-between">
                <FormGroup
                  for="firstName"
                  label="First Name"
                  class="w-50-100"
                >
                  <FormInput
                    bind-id="firstName"
                    get="firstName"
                    set="UPDATE_FIRST_NAME"
                  />
                </FormGroup>
                <FormGroup
                  for="lastName"
                  label="Last Name"
                  class="w-50-100"
                >
                  <FormInput
                    bind-id="lastName"
                    get="lastName"
                    set="UPDATE_LAST_NAME"
                  />
                </FormGroup>
              </div>
              <!-- EMAIL -->
              <FormGroup
                for="email"
                label="Email Address"
                class="w-100"
              >
                <FormInput
                  :readonly="!noEmail"
                  bind-id="email"
                  get="email"
                  set="UPDATE_EMAIL"
                  placeholder="the_best_explorer@aira.io"
                />
              </FormGroup>
            </FormSection>
            <!-- SHIPPING INFORMATION -->
            <FormSection
              v-if="hardwareSelected !== 'none'"
              title="Shipping Information"
            >
              <FormGroup
                for="sameShippingBilling"
                class="w-100"
              >
                <FormInput
                  bind-id="sameShippingBilling"
                  type="checkbox"
                  label="Shipping Address is same as Billing Address"
                  get="sameShippingBilling"
                  set="TOGGLE_SAME_SHIPPING_BILLING"
                  class="w-100"
                />
              </FormGroup>
              <div v-if="!sameShippingBilling">
                <!-- ADDRESS 1 -->
                <FormGroup
                  for="shippingAddress1"
                  label="Shipping Address 1"
                  class="w-100"
                >
                  <FormInput
                    bind-id="shippingAddress1"
                    required
                    get="shippingAddress1"
                    set="UPDATE_SHIPPING_ADDRESS1"
                  />
                </FormGroup>
                <!-- ADDRESS 2 -->
                <FormGroup
                  for="shippingAddress2"
                  label="Shipping Address 2 (Optional)"
                  class="w-100"
                >
                  <FormInput
                    bind-id="shippingAddress2"
                    get="shippingAddress2"
                    set="UPDATE_SHIPPING_ADDRESS2"
                  />
                </FormGroup>
                <!-- CITY & STATE/REGION/PROVINCE -->
                <div class="d-sm-flex flex-wrap justify-content-sm-between">
                  <FormGroup
                    for="shippingCountry"
                    label="Shipping Country"
                    class="w-50-100"
                  >
                    <FormSelect
                      :options="countryOptions"
                      bind-id="shippingCountry"
                      get="shippingCountry"
                      set="UPDATE_SHIPPING_COUNTRY"
                    />
                  </FormGroup>
                  <FormGroup
                    v-if="shippingStateOptions.length"
                    for="shippingState"
                    label="Shipping State/Region/Province"
                    class="w-50-100"
                  >
                    <FormSelect
                      :options="shippingStateOptions"
                      bind-id="shippingState"
                      get="shippingState"
                      set="UPDATE_SHIPPING_STATE"
                      required
                    />
                  </FormGroup>
                  <FormGroup
                    for="shippingCity"
                    label="Shipping City"
                    class="w-50-100"
                  >
                    <FormInput
                      bind-id="shippingCity"
                      required
                      get="shippingCity"
                      set="UPDATE_SHIPPING_CITY"
                    />
                  </FormGroup>
                  <!-- ZIP -->
                  <FormGroup
                    for="shippingZip"
                    label="Shipping Zip/Postal Code"
                    class="w-50-100"
                  >
                    <FormInput
                      bind-id="shippingZip"
                      required
                      get="shippingZip"
                      set="UPDATE_SHIPPING_ZIP"
                    />
                  </FormGroup>
                </div>
              </div>
            </FormSection>
          </div>
        </div>
      </div>
      <div
        v-if="phoneVerified"
        class="buttons"
      >
        <!-- ===== ORDER SUMMARY ===== -->
        <b-button
          v-if="!orderReviewed"
          :disabled="previewButtonDisabled"
          class="signup-buttons w-75"
          size="lg"
          variant="secondary"
          @click="getOrderSummaryPreview()"
        >
          <span v-if="previewButtonDisabled">
            <b-spinner label="processing" />
          </span>
          <span v-else>Get Order Summary</span>
        </b-button>
        <SignupOrderSummary v-if="resellerOrderSummary.length && orderReviewed" />
        <b-button
          v-if="orderReviewed"
          :disabled="submitButtonDisabled"
          class="signup-buttons w-75"
          size="lg"
          variant="primary"
          @click="postResellerOrder()"
        >
          <span v-if="submitButtonDisabled">
            <b-spinner label="submitting" />
          </span>
          <span v-else>Submit</span>
        </b-button>
      </div>
    </b-form>
  </div>
  <div v-else>
    <Error403 />
  </div>
</template>

<script>
import _ from 'lodash';
import { mapGetters } from 'vuex';
import { validateEmail } from '../../utils/validation';
import * as role from '../../utils/permissions';
import hardware from '../../data/hardware.json';

import Error403 from '../Error/403.vue';

import FormErrors from '../Form/FormErrors.vue';
import FormProgressBar from '../Form/FormProgressBar.vue';
import FormSection from '../Form/FormSection.vue';
import FormGroup from '../Form/FormGroup.vue';
import FormInput from '../Form/FormInput.vue';
import FormSelect from '../Form/FormSelect.vue';
import FormRadio from '../Form/FormRadio.vue';
import SignupSummaryInfo from '../Signup/SignupComponents/SignupSummaryInfo.vue';
import SignupOrderSummary from '../Signup/SignupComponents/SignupOrderSummary.vue';

export default {
  name: 'ResellerPurchaseOrder',
  components: {
    Error403,
    FormErrors,
    FormProgressBar,
    FormSection,
    FormGroup,
    FormInput,
    FormSelect,
    FormRadio,
    SignupSummaryInfo,
    SignupOrderSummary,
  },
  data() {
    return {
      pageName: 'resellerPurchaseOrder',
      initializing: true,
      phoneVerifyButtonDisabled: false,
      phoneVerified: false,
      phoneVerifyStatus: null,
      previewButtonDisabled: false,
      orderReviewed: false,
      submitButtonDisabled: false,
    };
  },
  computed: {
    ...mapGetters([
      'debug',
      'errors',
      'countryCodeOptions',
      'countryOptions',
      'operatingCountryOptions',
      'stateOptions',
      'shippingStateOptions',
      'planOptions',
      'planCode',
      'plan',
      'operatingCountry',
      'phoneNumber',
      'phone',
      'hardwareOptions',
      'hardwareSelected',
      'hardwareSelectedInfo',
      'horizonSize',
      'horizonTint',
      'sameShippingBilling',
      'shippingAddress1',
      'shippingState',
      'shippingCity',
      'shippingCountry',
      'shippingZip',
      'firstName',
      'lastName',
      'email',
      'noEmail',
      'orderSummary',
      'resellerOrderSummary',
      'cardType',
      'achAccountType',
    ]),
    hardwarePreferences() {
      if (!this.hardwareSelected || this.hardwareSelected === 'none') return [];
      const pref = hardware[this.hardwareSelected].preferences;
      if (!pref) return [];
      return hardware.preferences[pref];
    },
    fieldValues() {
      return [this.planCode, this.hardwareSelected, this.horizonSize, this.horizonTint, this.firstName, this.lastName, this.email, this.sameShippingBilling, this.shippingAddress1, this.shippingState, this.shippingCity, this.shippingCountry, this.shippingZip].join();
    },
  },
  watch: {
    fieldValues: {
      deep: true,
      // eslint-disable-next-line
      handler: _.debounce(function () {
        this.orderReviewed = false;
      }, 200),
    },
    planCode() {
      this.$store.dispatch('setPlan');
    },
    phone() {
      if (this.phoneVerified) {
        this.phoneVerified = false;
        this.phoneVerifyStatus = false;
        this.$store.dispatch('resetUserStatus');
      }
      if (this.noPhone) this.phoneVerified = true;
    },
    operatingCountry() {
      this.$store.dispatch('getInternalSignupPlans');
      this.$store.commit('UPDATE_COUNTRY', this.operatingCountry);
      this.$store.commit('UPDATE_SHIPPING_COUNTRY', this.operatingCountry);
    },
  },
  async beforeCreate() {
    await this.$store.dispatch('getResellerPlans');
    await this.$store.dispatch('getUserBilling');
    this.initializing = false;
  },
  mounted() {
    this.$store.dispatch('resetUserStatus');
    if (this.errors.length) this.$store.commit('CLEAR_ERRORS');
  },
  methods: {
    hasPageAccess: role.hasPageAccess,
    hasComponentAccess: role.hasComponentAccess,
    progress() { return { full: true }; },
    clickBack() {
      this.$router.push('/reseller/');
    },
    async verifyPhone() {
      if (this.phoneVerifyStatus) return;
      this.phoneVerifyButtonDisabled = true;
      // TODO throttle
      this.$store.commit('CLEAR_ERRORS');
      if (this.phoneNumber.length < 7) {
        this.phoneVerifyStatus = false;
        this.phoneVerifyButtonDisabled = false;
        return;
      }
      this.$store.dispatch('resellerVerifyPhone').then((response) => {
        console.log(response);
        switch (response) {
          case 'guest':
            this.phoneVerified = true;
            this.phoneVerifyStatus = true;
            break;
          case 'new':
            this.phoneVerified = true;
            this.phoneVerifyStatus = true;
            break;
          case 'existing':
            this.phoneVerified = false;
            this.phoneVerifyStatus = false;
            this.$store.commit('PUSH_ERRORS', ['It appears this customer already has an active subscription. Please contact ryan.bishop@aira.io, to cancel the active subscription and replace it with this purchase.']);
            break;
          default:
            this.phoneVerifyStatus = false;
            this.$store.commit('PUSH_ERRORS', ['Phone number is invalid']);
        }
        this.phoneVerifyButtonDisabled = false;
      });
    },
    async getOrderSummaryPreview() {
      this.previewButtonDisabled = true;
      if (this.errors.length) this.$store.commit('CLEAR_ERRORS');
      const fieldErrors = [];

      // check fields
      if (!this.planCode) fieldErrors.push('Plan');
      if (this.hardwareSelected !== 'none' && this.hardwareSelected !== 'US-NFB-001') {
        if (!this.horizonSize) fieldErrors.push('Horizon Size');
        if (!this.horizonTint) fieldErrors.push('Horizon Tint');
      }
      if (this.hardwareSelected !== 'none' && !this.sameShippingBilling) {
        if (!this.shippingAddress1) fieldErrors.push('Shipping Address 1');
        if ((this.shippingCountry !== 'GB') && !this.shippingState) fieldErrors.push('Shipping State/Region/Province');
        if (!this.shippingCity) fieldErrors.push('Shipping City');
        if (!this.shippingZip) fieldErrors.push('Shipping Zip/Postal Code');
      }
      if (!this.firstName) fieldErrors.push('First Name');
      if (!this.lastName) fieldErrors.push('Last Name');
      if (!this.email) fieldErrors.push('Email');
      if (this.email && !validateEmail(this.email)) fieldErrors.push('Email not valid');
      if (fieldErrors.length) {
        this.$store.commit('PUSH_ERRORS', fieldErrors);
        this.previewButtonDisabled = false;
        return;
      }
      try {
        this.$store.dispatch('getResellerOrderPreview').then(() => {
          this.previewButtonDisabled = false;
          this.orderReviewed = true;
        });
      } catch (error) {
        console.error(error);
        this.previewButtonDisabled = false;
      }
    },
    async postResellerOrder() {
      this.submitButtonDisabled = true;
      if (this.errors.length) this.$store.commit('CLEAR_ERRORS');
      const fieldErrors = [];

      // check fields
      if (!this.planCode) fieldErrors.push('Plan');
      if (this.hardwareSelected !== 'none' && this.hardwareSelected !== 'US-NFB-001') {
        if (!this.horizonSize) fieldErrors.push('Horizon Size');
        if (!this.horizonTint) fieldErrors.push('Horizon Tint');
      }
      if (this.hardwareSelected !== 'none' && !this.sameShippingBilling) {
        if (!this.shippingAddress1) fieldErrors.push('Shipping Address 1');
        if ((this.shippingCountry !== 'GB') && !this.shippingState) fieldErrors.push('Shipping State/Region/Province');
        if (!this.shippingCity) fieldErrors.push('Shipping City');
        if (!this.shippingZip) fieldErrors.push('Shipping Zip/Postal Code');
      }
      if (!this.firstName) fieldErrors.push('First Name');
      if (!this.lastName) fieldErrors.push('Last Name');
      if (!this.email) fieldErrors.push('Email');
      if (this.email && !validateEmail(this.email)) fieldErrors.push('Email not valid');
      if (fieldErrors.length) {
        this.$store.commit('PUSH_ERRORS', fieldErrors);
        this.submitButtonDisabled = false;
        return;
      }
      try {
        this.$store.dispatch('postResellerOrder').then((response) => {
          if (response) {
            // eslint-disable-next-line
            if (confirm('Great! The customer is all ready to go. After that, if they have a glass subscription, they can expect a shipment notification of their glasses in the next couple days (US/CA only). Remind them to download the app and sign in as soon as possible to start working with agents right away.')) {
              this.$store.commit('SET_NEED_REFRESH');
              this.$router.push('/reseller');
            } else {
              this.$store.commit('SET_NEED_REFRESH');
              this.$router.push('/reseller');
            }
          }
          this.submitButtonDisabled = false;
        });
      } catch (error) {
        console.error(error);
        this.submitButtonDisabled = false;
      }
    },
  },
};
</script>

<style lang="scss">
@import '../../styles/variables/_colors.scss';
@import '../../styles/variables/_sizes.scss';
@import '../../styles/theme/_default.scss';

#reseller-purchase-order {
  margin-top: 50px;
  .form-container {
    position: relative;
    text-align: left;
  }
  .back-button {
    position: absolute;
    top: 25px;
    left: 15px;
    display: inline;
    color: black;
  }
  .form {
    padding: 80px 20px 0px 20px;
  }
  .spinner {
    margin: 40px;
  }
  .billing {
    height: 150px;
  }
  #phone-verify-button {
    margin-top: 21px;
  }
  #plan-info {
    padding: 10px 35px;
  }
  .buttons {
    text-align: center;
  }
  .signup-buttons {
    margin-bottom: 20px;
  }
}
</style>
